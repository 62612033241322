import React, { Component } from "react"

class Tabs extends Component {

	render() {
		const layout = this.props.content
		return (
			layout.relationships.field_tab_teaser !== null ? (
				<>
				<div className="content-wrp">
					{layout.relationships.field_tab_teaser ? (
						<ul id="component-tab-bottom" className="uk-switcher">
							{layout.relationships.field_tab_teaser.map((tab,tkey) => (

								<li key={"tab" + tkey}>
									<div className="uk-grid uk-child-width-1-2@m">
										<div>
											{tab.relationships.field_media_image && tab.relationships.field_media_image.relationships.field_media_image ? (
												tab.field_video_link ? (
													<div className="bgr-image video-bgr" style={{backgroundImage: `url(${tab.relationships.field_media_image.relationships.field_media_image.uri.url})`}}>
														<a href={tab.field_video_link.uri.replace("internal:","")} target="_blank" rel="noreferrer">
															<img src={tab.relationships.field_media_image.relationships.field_media_image.uri.url} alt="tab" />
														</a>
													</div>
												) : (
													<div className="bgr-image" style={{backgroundImage: `url(${tab.relationships.field_media_image.relationships.field_media_image.uri.url})`}}>
														<img src={tab.relationships.field_media_image.relationships.field_media_image.uri.url} alt="tab" />
													</div>
												)
											) : (
												null
											)}
										</div>
										<div className="tab-content-right-wrp">
											<div className="content-right">
											  {tab.field_link && tab.field_title? (
											  	<a className="link-title" href={tab.field_link.uri.replace("internal:","")}><h2 className="h3">{tab.field_title}</h2></a>
											  ) : (
													<h2 className="h3">{tab.field_title}</h2>
											  )}
												<p>{tab.field_description}</p>
													<div className="button-wrp">
														{tab.field_link ? (
															<a href={tab.field_link.uri.replace("internal:","")}  className="uk-button green-bgr">{tab.field_link.title}</a>
														) : (
															null
														)}
														{tab.field_link_2 ? (
															<a href={tab.field_link_2.uri.replace("internal:","")}  className="uk-button green-bgr">{tab.field_link_2.title}</a>
														) : (
															null
														)}
														{tab.field_video_link ? (
															<a href={tab.field_video_link.uri.replace("internal:","")}  className="uk-button green-bgr" target="_blank" rel="noreferrer">{tab.field_video_link.title}</a>
														) : (
															null
														)}
													</div>
											</div>
										</div>
									</div>
								</li>
							))}
						</ul>
					) : (
						null
					)}
				</div>
				<div className="tab-wrp" data-time={layout.field_rotation_interval}>
				{layout.relationships.field_tab_teaser ? (
					<ul className="uk-tab-bottom" data-uk-tab="connect: #component-tab-bottom">
						{layout.relationships.field_tab_teaser.map((tab,tkey) => (
							<li key={"tabnav"+tkey}><a href="/#" title={tab.field_tab_title} aria-label={tab.field_tab_title} data-key={tkey}>{tab.field_tab_title}<span/></a></li>
						))}
					</ul>
				) : (
					null
				)}
				</div>
				</>
			) : (
				null
			)
		)
	}
}

export default Tabs
