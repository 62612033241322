import React, { Component } from "react"

class CustomTeaser extends Component {

	render() {
		const layout = this.props.content
		return (
			layout.relationships.field_item !== null ? (
				<div className="container">
					<div className="all-news-wrapper">
						<div className="uk-grid" data-uk-grid>
							{layout.relationships.field_media_image && layout.relationships.field_media_image.relationships.field_media_image ? (
								<div className="uk-width-1-4@m">
									<div className="press-image" style={{backgroundImage: `url(${layout.relationships.field_media_image.relationships.field_media_image.uri.url})`}} >
										{layout.field_link ? (
											layout.field_link.uri.indexOf('internal') !== -1 ? (
												<a href={layout.field_link.uri.replace('internal:','')}>&nbsp;</a>
											) : (
												<a href={layout.field_link.uri.replace.indexOf}>&nbsp;</a>
											)
										) : (
											null
										)}
									</div>
								</div>
							) : (
								null
							)}
							<div className="uk-width-expand">
								<div className="news-content">
									<div className="news-content-body">
										{layout.field_title ? (
											<h3 className="h3">{layout.field_title}</h3>
										) : (
											null
										)}

										{layout.field_description ? (
											<span>{layout.field_description}</span>
										) : (
											null
										)}

									</div>
									{layout.field_link ? (
										layout.field_link.uri.indexOf('internal') !== -1 ? (
											<a className="more" href={layout.field_link.uri.replace('internal:','')}>{layout.field_link.title}</a>
										) : (
											<a className="more" href={layout.field_link.uri.replace('internal:','')}>{layout.field_link.title}</a>
										)
									) : (
										null
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				null
			)
		)
	}
}

export default CustomTeaser