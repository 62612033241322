import React, { Component } from "react"

class Banner extends Component {

	render() {
		const layout = this.props.content
		return (
			layout.relationships.field_links_section !== null ? (
				<div className="banner-wrp">

					<div className="uk-grid uk-flex">

						<div className="uk-width-1-4@m">
							{layout.relationships.field_media_image ? (
								<div className="uk-flex uk-flex-center uk-flex-middle uk-background-cover image-wrp" style={{backgroundImage: `url(${layout.relationships.field_media_image.relationships.field_media_image.uri.url})`}}>
									<img src={layout.relationships.field_media_image.relationships.field_media_image.uri.url} alt="slider" />
								</div>
							) : (
								null
							)}

							</div>

						<div className="uk-width-expand@m">
							<div className="banner-content">
								{layout.field_title ? (
									<h2>{layout.field_title}</h2>
								) : (
									null
								)}

								<div className="white-text" dangerouslySetInnerHTML={{ __html: layout.field_description }} />

							</div>
						</div>

						<div className="uk-width-auto@m uk-flex uk-flex-center uk-flex-middle">
							{layout.field_link ? (
								<div className="banner-link">
									<div className="button-wrp">
										{layout.field_link.uri.indexOf("http") > -1 ? (
											<a className="uk-button white-bgr" href={layout.field_link.uri.replace("internal:","")} target="_blank" rel="noreferrer">
												{layout.field_link.title.length > 15 ? (
													layout.field_link.title.slice(0,15) + "..."
												) : (
													layout.field_link.title
												)}
											</a>
										) : (
											<a className="uk-button white-bgr" href={layout.field_link.uri.replace("internal:","")}>
												{layout.field_link.title.length > 15 ? (
													layout.field_link.title.slice(0,15) + "..."
												) : (
													layout.field_link.title
												)}
											</a>
										)}
									</div>
								</div>
							) : (
								null
							)}
						</div>


					</div>
				</div>
			) : (
				null
			)
		)
	}
}

export default Banner
