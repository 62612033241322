import React, { Component } from "react"

class Accordion extends Component {

	render() {
		const layout = this.props.content
		return (
			layout.relationships.field_item !== null ? (
				<div className="accordion-section">
					<div className="container">
						<div className="uk-grid" data-uk-grid>

							<div className={layout.field_half_width ? "uk-width-1-2@l" : "uk-width-1-1@l" }>
								<div className="accordion-wrapp">

									<div className="uk-flex uk-flex-between	uk-flex-top">
										{layout.field_title ? (
												<h2>{layout.field_title}</h2>
										) : (
											null
										)}
										<a className="uk-button expand-all open" href="#">Expand all</a>
									</div>
									<ul className="uk-accordion accordion-content" data-uk-accordion={layout.field_animate_items ? "multiple: true; animation:false":"multiple: true"}>
										{layout.relationships.field_accordion_item && layout.relationships.field_accordion_item.map((item,key) => (
											<li key={"accordion-item"+key}>
												<a className="uk-accordion-title h3" href="#">{item.field_title}</a>
												{item.field_description_wysiwyg ? (
													<div className={layout.field_animate_items ? "uk-accordion-content uk-animation-scale-up uk-animation-slide-left-medium" : "uk-accordion-content"} hidden >
														<div dangerouslySetInnerHTML={{ __html: item.field_description_wysiwyg.processed }} />
													</div>
												) : (
													null
												)}
												<hr/>
											</li>

										))}
									</ul>
								</div>
							</div>

						</div>
					</div>
				</div>
			) : (
				null
			)
		)
	}
}

export default Accordion
