import React, { Component } from "react"
import alertIcon from "../images/alert-icon-small.svg"
import mapImage from "../images/location-green.svg"
import phoneImage from "../images/phone-green.svg"
import emailImage from "../images/mail-green.svg"
import greenArrow from "../images/arrow-green-left.svg"

class AgencyOverview extends Component {

	render() {
		const layout = this.props.content
		const currentPage = this.props.page
		const orientation = this.props.orientation
		return (
			<div className="container">
				<ul data-uk-accordion="toggle: > div > .uk-accordion-title" className="uk-accordion">
					<li className="uk-open">
						{layout.field_title ? (
							<div className="accordion-title-wrp uk-flex">
								<div className="headline-wrp">
									<h2 className="h3">{layout.field_title}</h2>
									{currentPage.field_link ? (
										<a className="alert-link" href={currentPage.field_link.uri.replace("internal:","")}>
											<img className="alert-icon" src={alertIcon} alt="alert-icon" />
											{currentPage.field_link.title}
											<img className="arrow-icon" src={greenArrow} alt="arrow-icon" />
										</a>
									) : (
										null
									)}
								</div>
							</div>
						) : (
							null
						)}

						<div className="uk-accordion-content">
							<div className="uk-grid" data-uk-grid="">

								{orientation === 'left' ? (
									layout.relationships.field_media_image ? (
										<div className="uk-width-1-6@m">
											<div className="agency-director-wrp">
												<img src={layout.relationships.field_media_image.relationships.field_media_image.uri.url} alt={layout.field_name} />

											</div>
										</div>
									) : (
										<div className="uk-width-1-6@m">
											{layout.field_name ? (
												<div className="name-title">
													<div className="name">{layout.field_name}</div>
													<div className="title">{layout.field_position}</div>
												</div>
											) : (
												null
											)}
										</div>
									)
								) : (
									null
								)
								}

								<div className="uk-width-5-6@m uk-first-column">
									{layout.field_description ? (
										<p>{layout.field_description}</p>
									) : (
										null
									)}

									{layout.field_description_wysiwyg ? (
										<div dangerouslySetInnerHTML={{ __html: layout.field_description_wysiwyg.processed }} />
									) : (
										null
									)}

									{layout.field_link ? (
										<a className="more" href={layout.field_link.uri.replace("internal:","")}>READ MORE</a>
									) : (
										null
									)}

									<div className="uk-grid uk-child-width-1-3@s" data-uk-grid>
										{layout.relationships.field_contact.map((contact,index) =>(
											contact.__typename === 'paragraph__contact_address' ? (
												<div className="contact-info" key={index}>
													<img src={mapImage} alt="map" />
													<div className="address">
														<div className="address-line-1" dangerouslySetInnerHTML={{ __html: contact.field_address }} />
														<div className="address-line-2" dangerouslySetInnerHTML={{ __html: contact.field_address_line_2 }} />

														<div>
															<a className="viewmap" href={"https://www.google.com/maps/search/" + contact.field_address + contact.field_address_line_2} target="_blank" rel="noreferrer">View Map</a>
															<a className="directions" href={"https://www.google.com/maps/dir/Current+Location/" + contact.field_address + contact.field_address_line_2} target="_blank" rel="noreferrer">Get Directions</a>
														</div>
													</div>
												</div>
											) : (
												contact.__typename === 'paragraph__contact_phone' ? (
													<div className="contact-info" key={index}>
														<img src={phoneImage} alt="phone" />
														<div className="contact-info-wrapp">
															<a href={"tel:" + contact.field_phone}>{contact.field_phone}</a>
															{contact.field_title ? (
																<div>{contact.field_title}</div>
															) : (
																null
															)}
														</div>
													</div>
												) : (
													contact.__typename === 'paragraph__contact_email' ? (
														<div className="contact-info" key={index}>
															<img src={emailImage} alt="emailImage" />
															<div className="contact-info-wrapp">
																<a href={"mailto:" + contact.field_email}>{contact.field_email}</a>
															</div>
														</div>
													) : (
														contact.__typename === 'paragraph__contact_custom_text' ? (
															<div className="contact-info" key={index}>
																{contact.field_description_wysiwyg ? (
																	<div dangerouslySetInnerHTML={{ __html: contact.field_description_wysiwyg.processed }} />
																) : (
																	null
																)}

															</div>
														) : (
															contact.__typename === 'paragraph__contact_mobile_phone' ? (
																<div className="contact-info" key={index}>
																	<img src={phoneImage} alt="phone" />
																	<div className="contact-info-wrapp">
																		<a href={"tel:" + contact.field_phone}>{contact.field_phone}</a>

																	</div>
																</div>
															) : (
																contact.__typename === 'paragraph__contact_fax' ? (
																	<div className="contact-info" key={index}>
																		<img src={phoneImage} alt="phone" />
																		<div className="contact-info-wrapp">
																			<a href={"tel:" + contact.field_phone}>{contact.field_phone}</a>
																		</div>
																	</div>
																) : (
																	null
																)
															)
														)
													)
												)
											)
										))}
									</div> {/*1-3*/}
								</div>

								{orientation === 'right' ? (
									layout.relationships.field_media_image ? (
										<div className="uk-width-1-6@m">
											<div className="agency-director-wrp">
												<img src={layout.relationships.field_media_image.relationships.field_media_image.uri.url} alt={layout.field_name} />

											</div>
										</div>
									) : (
										<div className="uk-width-1-6@m">
											{layout.field_name ? (
												<div className="name-title">
													<div className="name">{layout.field_name}</div>
													<div className="title">{layout.field_position}</div>
												</div>
											) : (
												null
											)}
										</div>
									)
								) : (
									null
								)
								}

							</div> {/*uk-grid*/}
						</div> {/*uk-accordion-content*/}
					</li>
				</ul>
			</div>
		)
	}
}

export default AgencyOverview
