import React, { Component } from "react"

class RepeatingIcons extends Component {

	render() {
		const layout = this.props.content
		return (
			layout.relationships.field_item !== null ? (
				<div className="container">
					<div className="uk-grid uk-grid-divider uk-child-width-1-2@m uk-child-width-1-4@l uk-flex uk-text-center" data-uk-grid>
						{layout.relationships.field_item.map((icon,ikey) => (
							<div className="single-box" key={"box"+ikey}>
								<div className="box-wrp">
										{icon.relationships.field_icon ? (
											<div className="box-image-wrp">
												<img src={icon.relationships.field_icon.uri.url} alt="icon" />
											</div>
										) : (
											null
										)}

									<div className="box-content">
										<div className="box-content-wrp">
											{icon.field_title && icon.field_title !== null ? (
												<h3>{icon.field_title}</h3>
											) : (
												null
											)}
											{icon.field_description && icon.field_description !== null ? (
												<span>{icon.field_description}</span>
											) : (
												null
											)}
										</div>
										{icon.field_link ? (
											<div className="button-wrp">
												<a href={icon.field_link.uri.replace("internal:","")} className="uk-button green-bgr">{icon.field_link.title}</a>
											</div>
										) : (
											null
										)}

									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			) : (
				null
			)
		)
	}
}

export default RepeatingIcons
