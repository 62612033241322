import React, { Component } from "react"

class ContactTabs extends Component {
	render() {
	const contactTabs = this.props.tabs


		return (
			contactTabs !== null ? (
				<>
					<div className="container">
					<ul id="switcher-tabs" className="uk-grid uk-child-width-auto@m uk-subnav uk-subnav-pill uk-grid-collapse tab-bar" data-uk-switcher="animation: uk-animation-fade">
						{contactTabs && contactTabs.map((tab,key) => (
						<li id={tab.field_title.replace(' ','-').toLowerCase()} key={"tab"+key}><a className="departments tab-link" title={tab.field_title} aria-label={tab.field_title} href="#" >{tab.field_title}</a></li>
						))}
					</ul>
					<div className="uk-switcher tab-content">
						{contactTabs && contactTabs.map((tab,key) => (
						<div className="single-tab-content" key={key}>
							{tab.field_description_wysiwyg ? (
							<div className="tab-content-info" dangerouslySetInnerHTML={{ __html: tab.field_description_wysiwyg.processed }} />
							) : (
							null
							)}
							{tab.relationships.field_pool_info && tab.relationships.field_pool_info.map((pool,index) => (
							<div className="pool-wrapper" key={index}>
								<div className="uk-grid" data-uk-grid>
								<div className="uk-width-1-4@m">
									{pool.relationships.field_media_image ? (
									<div className="press-image" style={{backgroundImage: `url(${pool.relationships.field_media_image.relationships.field_media_image.uri.url})`}} />
									) : (
									null
									)}
								</div>
								<div className="uk-width-3-4@m">
									{pool.field_title ? (
									<h4>{pool.field_title}</h4>
									) : (
									null
									)}

									<div className="uk-grid uk-child-width-1-2@m contact-info" >
									{pool.relationships.field_contact_info && pool.relationships.field_contact_info.map((contact,key) => (
										contact.__typename === 'paragraph__contact_address' ? (
										<div key={'paragraph__contact_address' + key}>
											<div className="location">
											{contact.field_address ? (
												<div className="address-line-1">{contact.field_address} (<a className="directions" href={"https://www.google.com/maps/dir/Current+Location/" + contact.field_address} target="_blank" rel="noreferrer">Get Directions</a>)</div>
											) : (
												null
											)}
											{contact.field_address_line_2 ? (
												<div className="address-line-1">{contact.field_address_line_2} (<a className="directions" href={"https://www.google.com/maps/dir/Current+Location/" + contact.field_address_line_2} target="_blank" rel="noreferrer">Get Directions</a>)</div>
											) : (
												null
											)}

											</div>
										</div>
										) : (
										contact.__typename === 'paragraph__contact_phone' ? (
											<div key={'paragraph__contact_phone' + key}>
											{contact.field_phone ? (
												<div className="phone">
												<a href={"tel:"+contact.field_phone}>{contact.field_phone}</a>
												</div>
											) : (
												null
											)}
											{contact.field_title ? (
												<div className="working-ours">
													<p>{contact.field_title}</p>
												</div>
											) : (
												null
											)}
											</div>
										) : (
											contact.__typename === 'paragraph__contact_custom_text' ? (
											<div key={'paragraph__contact_custom_text' + key}>
												<div className="time" dangerouslySetInnerHTML={{ __html: contact.field_description_wysiwyg.processed}} />
											</div>
											) : (
												null
											)
										)
										)
									))}
									</div>
									{pool.field_description_wysiwyg ? (
									<div className="about" dangerouslySetInnerHTML={{ __html: pool.field_description_wysiwyg.processed}} />
									) : (
									null
									)}

								</div>
								</div>
							</div>

							))}
						</div>
						))}
					</div>
					</div>
				</>
			) : (
				null
			)
		)
	}
}

export default ContactTabs