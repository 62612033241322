import React, { Component } from "react"

class LinkList extends Component {

	render() {
		const layout = this.props.content
		return (
			layout.relationships.field_links_section !== null ? (
				<div className="uk-grid-large uk-grid-divider uk-child-width-1-2@s uk-child-width-1-3@m" data-uk-grid>
					{layout.relationships.field_links_section && layout.relationships.field_links_section.map((group,key) => (
						<div key={"group"+key}>
							<h3 className="h3">{group.field_title}</h3>
							{group.field_repeating_link && group.field_repeating_link.map((glink,gkey) => (
									<div className="links-list-wrp" key={"link"+gkey}>
										<a href={glink.uri.replace("internal:","")}>{glink.title}</a>
									</div>
							))}
						</div>
					))}
				</div>
			) : (
				null
			)
		)
	}
}

export default LinkList