import React, { Component } from "react"
import LeftArrow from "../images/arrow-slider-left.svg"
import RightArrow from "../images/arrow-slider-right.svg"

class Slider extends Component {

	render() {
		const layout = this.props.content
		return (
			layout.relationships.field_slider_item !== null ? (
				<div className="container-slideshow">

					<div className="js-slideshow-animation uk-slideshow" data-uk-slideshow="ratio: 16:9; animation: fade; autoplay: true;" >

						<div className="uk-position-relative uk-visible-toggle slideshow-wrp" >

							<ul className="uk-slideshow-items">
								{layout.relationships.field_slider_item.map((slider,key) => (
									slider.__typename === 'paragraph__slider_image' ? (
										slider.relationships.field_media_image && slider.relationships.field_media_image.relationships.field_media_image && slider.relationships.field_media_image.relationships.field_media_image.uri ? (
											<li data-uk-lightbox key={"slider-item"+key}>
												<a data-caption={slider.field_description} href={slider.relationships.field_media_image.relationships.field_media_image.uri.url}><img src={slider.relationships.field_media_image.relationships.field_media_image.uri.url} alt="Image1"/></a>
												{slider.field_description ? (
													<div className="slide-image-description">
														{slider.field_description}
													</div>

												) : (
													null
												)}
											</li>
										) : (
											null
										)
									) : (
										slider.__typename === 'paragraph__slider_video' ? (
											slider.field_title ? (
												slider.field_youtube_or_vimeo === 'vimeo' ? (
													<li key={"slider-item"+key}>
														<iframe src={"https://player.vimeo.com/video/"+slider.field_title}></iframe>
													</li>
												) : (
													<li key={"slider-item"+key}>
														<iframe src={"https://www.youtube.com/embed/"+slider.field_title}></iframe>
													</li>
												)
											) : (
												null
											)
										) : (
											null
										)
									)
								))}
							</ul>

							<a className="uk-position-center-left uk-slidenav uk-hidden-hover" href="#" uk-slideshow-item="previous"><img src={LeftArrow}/></a>
							<a className="uk-position-center-right uk-slidenav uk-hidden-hover" href="#"  uk-slideshow-item="next"><img src={RightArrow}/></a>

						</div>
						<ul className="uk-slideshow-nav uk-dotnav uk-flex-center uk-margin">
							<li uk-slideshow-item="0" className=""><a href=""></a></li>
						</ul>

					</div>

				</div>
			) : (
				null
			)
		)
	}
}

export default Slider
