import React, { Component } from "react"

class TabsSection extends Component {

	render() {
		const layout = this.props.content
		return (
			layout.relationships.field_tab_item !== null ? (
				<div className="global-tabs">
					<div className="container">
						<ul className="uk-grid-collapse uk-child-width-1-3@m uk-child-width-1-5@l uk-subnav uk-subnav-pill uk-flex tab-bar" data-uk-switcher="animation: uk-animation-fade">
								{layout.relationships.field_tab_item.map((item,key) => (
									<li key={"tab-title"+key}><a href="#" title={item.field_title} aria-label={item.field_title}>{item.field_title}</a></li>
								))}
						</ul>
						<ul className="uk-switcher uk-margin">
								{layout.relationships.field_tab_item.map((item,key) => (
									<li key={"tab-content"+key}>
										{item.field_description_wysiwyg ? (
											<div dangerouslySetInnerHTML={{ __html: item.field_description_wysiwyg.processed }} />
										) : (
											null
										)}
									</li>
								))}
						</ul>
					</div>
				</div>
			) : (
				null
			)
		)
	}
}

export default TabsSection
